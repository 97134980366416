table.user tbody tr th, table.user tbody tr td {
    padding-bottom: .5em;
}

table.user tbody tr th {
    padding-right: 3em;
}

select.form-control {
    min-width: 10em;
}

table.user button {
    white-space: nowrap;
}

div.roleName {
    white-space: nowrap;
}