html {
  min-height: 100vh;
}

header {
  margin: 1rem 0;
}

div#root > main {
  min-height: 80vh;
}

form {
  margin-bottom: 1em;
}

header .navbar ul li.nav-item {
  font-family: Arial, Helvetica, sans-serif;
  font-size: .95rem;
}

@media screen and (min-width: 992px) {
	form.dataEntry {
		max-width: 45rem;
	}
}

/* show these as clickable */
.clickable,
label.form-check-label, input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

/* show these as not clickable/editable */
input[type="text"]:read-only,
input[type="checkbox"]:disabled, select:disabled,
textarea:read-only,
button:disabled, .form-check-label.disabled {
  cursor: not-allowed;
}

label {
  padding-right: 1em;
}

.DayPickerInput {
  width: 99%;
}

.btn-margin {
  margin-left: 2px;
  margin-right: 2px;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1160px;
  }
}

/** Minty theme overrides **/
/* make danger color less orange */
.btn-danger {
  background-color: #ff5831;
  border-color: #ff4831;
}
.btn-danger:hover {
  background-color: #ef4821;
  border-color: #ef3821;
}
.alert-danger {
  background-color: #ff5831;
}

/* make warning color easier to see against white */
.btn-warning {
  background-color: #ffbe57;
  border-color: #ffbe57;
}
.btn-warning:hover {
  background-color: #efae47;
  border-color: #efae47;
}
.alert-warning {
  background-color: #ffbe57;
}

/* alert padding and margin */
.alert {
  padding: 0.3rem .7rem;
  margin-bottom: .5rem;
}
/* form input border */
.form-control {
  border-color:#aeb4ba;
}
/* asterisk on required fields */
.form-group.required .asterisk-label::after {
  content: "*";
  color: #ff0000;
  padding-left: 0.5rem;
}
