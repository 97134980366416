
.card {
    margin-top:.5em;
    margin-bottom:.5em;
}

.card-body div.detail {
    margin: .5em;
}

.map {
    height:30em;
    max-height:480px;
    width:100%;
    border: 1px solid #666;
    margin-top:1em;
    margin-bottom:1em;
    text-align: center;
}
