  
table.micro tr td, table.micro tr th {
	font-size:.9rem;
	padding: .1em;
}
  
select.micro {
	padding: .1em .2em .1em .2em;
	width: 4em;
	font-size: .8em;
	margin: .5em;
}

div.outline {
	outline:thin solid #e6e6e6;
	padding: 1;
}
