
@keyframes spin {
	0% {transform: rotateZ(0);}
	100% {transform: rotateZ(360deg);}
}

#processing {
	width:5rem;
	height:5rem;
	border-radius:4rem;
	border:.8rem outset #999;
	
	padding: 0;
	margin: auto;
	position: fixed;
	top: 0; left: 0; bottom: 0; right: 0;
	z-index: 100;
	
	opacity: 0.3;
	animation: spin 1s linear infinite;
}
